var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subHeader base noFlex" }, [
    _c("h2", [_vm._v("결제 상세내역")]),
    _c(
      "section",
      [
        _c("table", { staticStyle: { "margin-top": "2rem" } }, [
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("결제상태")
            ]),
            _c("td", [
              _c(
                "p",
                {
                  staticClass: "msg",
                  class: {
                    green: _vm.status == "ready",
                    blue: _vm.status == "paid",
                    orange: _vm.status == "cancelled",
                    red: _vm.status == "failed"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.status == "ready"
                          ? "미결제"
                          : _vm.status == "paid"
                          ? "결제완료"
                          : _vm.status == "cancelled"
                          ? "결제취소"
                          : _vm.status == "failed"
                          ? "결제실패"
                          : ""
                      ) +
                      " "
                  )
                ]
              )
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("제품등록 번호")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [
                _vm._v(_vm._s(_vm.merchant_uid))
              ])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("사이즈")
            ]),
            _c("td", [
              _c("tr", [
                _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
                  _vm._v("기본사이즈")
                ]),
                _c("td", [
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(_vm._s(_vm.sizeInfo[0].size))
                  ])
                ]),
                _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
                  _vm._v("어깨")
                ]),
                _c("td", [
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(_vm._s(_vm.sizeInfo[0].shoulder))
                  ])
                ]),
                _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
                  _vm._v("가슴")
                ]),
                _c("td", [
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(_vm._s(_vm.sizeInfo[0].chest))
                  ])
                ]),
                _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
                  _vm._v("목")
                ]),
                _c("td", [
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(_vm._s(_vm.sizeInfo[0].neck))
                  ])
                ]),
                _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
                  _vm._v("허리")
                ]),
                _c("td", [
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(_vm._s(_vm.sizeInfo[0].waist))
                  ])
                ]),
                _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
                  _vm._v("기장")
                ]),
                _c("td", [
                  _c("p", { staticClass: "msg" }, [
                    _vm._v(_vm._s(_vm.sizeInfo[0].length))
                  ])
                ])
              ])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("스타일")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [
                _vm._v(_vm._s(_vm.sizeInfo[0].styleType))
              ])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("주문명")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [_vm._v(_vm._s(_vm.name))])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("결제날짜")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [
                _vm._v(
                  _vm._s(_vm.moment(_vm.paid_at).format("YYYY년 MM월 DD일"))
                )
              ])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("날짜")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.moment(_vm.started_at).format("YYYY년 MM월 DD일")
                    ) +
                    " "
                )
              ])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("결제방법")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.pay_method === "card"
                        ? "카드"
                        : _vm.pay_method === "vbank"
                        ? "가상계좌"
                        : _vm.pay_method === "trans"
                        ? "무통장입금"
                        : _vm.pay_method
                    ) +
                    " "
                )
              ])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("결제금액")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [
                _vm._v(_vm._s(_vm.comma(_vm.amount)) + " 원")
              ])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("구매자 이름")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [_vm._v(_vm._s(_vm.buyer_name))])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("구매자 전화번호")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [_vm._v(_vm._s(_vm.buyer_tel))])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("구매자 이메일")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [_vm._v(_vm._s(_vm.buyer_email))])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("구매자 요청사항")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [_vm._v(_vm._s(_vm.custom_data))])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("구매자 주소")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [
                _vm._v(
                  "[" +
                    _vm._s(_vm.buyer_postcode) +
                    "] " +
                    _vm._s(_vm.buyer_addr)
                )
              ])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("채널")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [_vm._v(_vm._s(_vm.channel))])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("화폐종류")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [_vm._v(_vm._s(_vm.currency))])
            ])
          ]),
          _c("tr", [
            _c("th", { staticClass: "done", attrs: { scope: "row" } }, [
              _vm._v("카드명")
            ]),
            _c("td", [
              _c("p", { staticClass: "msg" }, [_vm._v(_vm._s(_vm.card_name))])
            ])
          ])
        ]),
        _c(
          "router-link",
          { staticClass: "btn", attrs: { to: "/admin/payments" } },
          [_vm._v("목록")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }